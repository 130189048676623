import React, { Component } from "react";
import constants from "../constants";

class BrandIcon extends Component {
  state = {};
  render() {
    return (
      <img
        src={constants.logo}
        className="logo center"
        style={{ display: "inline-block" }}
        // style={{ marginTop: "-6px" }}
      />
    );
  }
}

export default BrandIcon;
