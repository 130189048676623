import Cookies from "js-cookie";
import constants, { alertError } from "../constants";

export function loadRoutes(onLoad) {
  fetch(constants.url + "routes", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Cookies.get("u-token")}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      onLoad(data.routes);
    })
    .catch((error) => {
      alertError("Unable to load routes");
      console.error(error);
    });
}

export function loadUsers(onLoad) {
  fetch(constants.url + "users", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Cookies.get("u-token")}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      onLoad(data.data);
    })
    .catch((error) => {
      console.error(error);
    });
}

export function loadProducts(onLoad) {
  fetch(constants.url + "products", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Cookies.get("u-token")}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      onLoad(data);
    })
    .catch((error) => {
      console.error(error);
    });
}

export function loadUsersFromRoute(routeId, onLoad) {
  fetch(constants.url + "routes/users/" + routeId, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Cookies.get("u-token")}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      onLoad(data.users);
    })
    .catch((error) => {
      alertError("Unable to load route users");
      console.error(error);
    });
}

export function loadHashTags(onLoad) {
  fetch(constants.url + "hash-tags", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Cookies.get("u-token")}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      onLoad(data);
    })
    .catch((error) => {
      alertError("Unable to load hash tags..");
      console.error(error);
    });
}

export function loadRouteWalks(routeId, onLoad) {
  fetch(constants.url + "route-walks/" + routeId, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Cookies.get("u-token")}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      onLoad(data.route_walks);
    })
    .catch((error) => {
      alertError("Unable to load route walks");
      console.error(error);
    });
}

export function loadDeliveryData(routes, date, onLoad) {
  fetch(constants.url + "delivery-data", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Cookies.get("u-token")}`,
    },
    body: JSON.stringify({ routes, date }), // Pass `date` to the backend
  })
    .then((response) => response.json())
    .then((data) => {
      onLoad(data);
    })
    .catch((error) => {
      alertError("Unable to load delivery data");
      console.error(error);
      onLoad(null);
    });
}

export function loadReturnsData(routes, onLoad) {
  fetch(constants.url + "returns-data", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Cookies.get("u-token")}`,
    },
    body: JSON.stringify({ routes }),
  })
    .then((response) => response.json())
    .then((data) => {
      onLoad(data.returns);
    })
    .catch((error) => {
      alertError("Unable to load returns data");
      console.error(error);
      onLoad(null);
    });
}

export function loadStats(onLoad) {
  fetch(constants.url + "stats", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Cookies.get("u-token")}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      onLoad(data);
    })
    .catch((error) => {
      alertError("Unable to load stats.");
      console.error(error);
    });
}

export function loadInventoryPlaces(onLoad) {
  fetch(constants.url + "inventory-places", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Cookies.get("u-token")}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      onLoad(data);
    })
    .catch((error) => {
      alertError("Unable to load places");
      console.error(error);
      // onLoad(null);
    });
}
export function loadInventoryUsers(onLoad) {
  fetch(constants.url + "inventory-users", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Cookies.get("u-token")}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      onLoad(data);
    })
    .catch((error) => {
      alertError("Unable to load users");
      console.error(error);
      // onLoad(null);
    });
}

export function loadNearest(location, onLoad) {
  fetch(constants.url + "find-nearest", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Cookies.get("u-token")}`,
    },
    body: JSON.stringify({
      location,
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      onLoad(data);
    })
    .catch((error) => {
      alertError("Unable to find nearest");
    });
}

export function findGeoSpots(route, onDone) {
  fetch(constants.url + `dm-find-geospots/${route.id}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Cookies.get("u-token")}`,
    },
    body: JSON.stringify({}),
  })
    .then((response) => response.json())
    .then((data) => {
      onDone(data);
    })
    .catch((error) => {
      alertError("Request failed");
    });
}

export function loadCVisits(route, onDone) {
  fetch(constants.url + `c-visits/${route}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Cookies.get("u-token")}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      onDone(data);
    })
    .catch((error) => {
      alertError("Unable to load c visits");
      onDone(null);
      console.error(error);
    });
}
