import React, { Component } from "react";
import "./map-action-bar.css";
import { FaArrowUp } from "react-icons/fa6";
import { loadRoutes } from "../../Loaders/GeneralLoder";
import Cookies from "js-cookie";
import { CheckPicker, Drawer } from "rsuite";
import MapCVisits from "./MapCVisits";
import CustomerView from "../../Pages/sub-pages/mobile/customer-view";
import MapReturns from "./MapReturns";

class MapActionBar extends Component {
  state = {
    expanded: false,
    rsWindow: false,
    routes: this.props.routes ?? [],
    selectedRoutes: JSON.parse(Cookies.get("mabSelectedRoutes") ?? "[]"),
    loading: false,
    selectedCustomer: null,
    selectedLead: null,
  };

  loading = (loading = true) => this.setState({ loading });

  componentDidMount = () => {
    if (!this.props.routes) {
      loadRoutes((routes) => this.setState({ routes }));
    }
  };

  render() {
    var {
      selectedRoutes,
      routes,
      loading,
      rsWindow,
      expanded,
      selectedCustomer,
      selectedLead,
    } = this.state;

    return (
      <>
        <div
          className={`mab-container ${
            this.state.expanded ? "mab-container-expanded" : ""
          }`}
        >
          <div
            className="mab-button"
            onClick={() => this.setState({ rsWindow: true })}
          >
            RS
          </div>
          <div className="mab-break"></div>
          <MapCVisits
            selectedRoutes={selectedRoutes}
            selectCustomer={(selectedCustomer) =>
              this.setState({ selectedCustomer })
            }
          />
          <MapReturns
            selectedRoutes={selectedRoutes}
            selectCustomer={(selectedCustomer) =>
              this.setState({ selectedCustomer })
            }
          />
          <div className="mab-break"></div>
          <div
            className={`mab-button ${expanded ? "mab-button-90" : ""}`}
            onClick={() => this.setState({ expanded: !expanded })}
          >
            <FaArrowUp />
          </div>
        </div>

        <div
          className={`route-select-window ${
            rsWindow ? "route-select-window-expanded" : ""
          }`}
        >
          <div
            className="mab-button close"
            onClick={() => this.setState({ rsWindow: false })}
          >
            X
          </div>
          <strong>Select Routes</strong>
          <CheckPicker
            virtualized
            size="sm"
            label="Routes"
            data={routes.map((route) => ({
              label: route.route_name,
              value: route.id,
            }))}
            loading={loading}
            value={selectedRoutes}
            onChange={(selectedRoutes) => {
              this.setState({ selectedRoutes });
              Cookies.set("mabSelectedRoutes", JSON.stringify(selectedRoutes));
            }}
          />
        </div>

        {/* Lead & Customer View Drawer */}
        <Drawer
          placement="bottom"
          size="full"
          open={selectedCustomer != null || selectedLead !== null}
          onClose={() => this.setState({ selectedCustomer: null })}
        >
          <Drawer.Header>
            <Drawer.Title>
              View {selectedCustomer ? "Customer" : "Lead"}
            </Drawer.Title>
          </Drawer.Header>
          <Drawer.Body>
            {selectedCustomer ? (
              <CustomerView
                data={selectedCustomer}
                back={() => this.setState({ selectedCustomer: null })}
                currentLocation={this.props.currentLocation}
                sessionCheck={this.props.sessionCheck}
              />
            ) : (
              ""
            )}
          </Drawer.Body>
        </Drawer>
      </>
    );
  }
}

export default MapActionBar;
