import React, { Component } from "react";
import { List, Tag, Badge, InputPicker } from "rsuite";
import moment from "moment";
import SalesQtyList from "../../../../Modules/SalesQtyList";

class UserSales extends Component {
  state = {
    listType: "Created",
  };
  render() {
    var { data } = this.props;

    var { listType } = this.state;

    var sales =
      listType == "Created"
        ? data?.sales
        : listType == "Completed"
        ? data?.salesCompleted
        : listType == "DeliveryAssigned"
        ? data.deliveriesAssigned
        : [];

    return (
      <div>
        <InputPicker
          data={["Created", "Completed", "DeliveryAssigned"].map((i) => ({
            label: i,
            value: i,
          }))}
          value={listType}
          onChange={(listType) => this.setState({ listType })}
        />
        <hr />
        {listType == "Created" ? (
          <div>
            <strong>
              Sales Value:{" "}
              {sales
                .filter(
                  (sale) =>
                    sale.status !== "pending" && sale.status !== "cancelled"
                ) // Exclude pending and cancelled sales
                .reduce(
                  (sum, sale) =>
                    sum +
                    sale.items.reduce(
                      (itemSum, item) =>
                        itemSum +
                        parseFloat(item.unit_price || 0) *
                          parseFloat(item.quantity || 0),
                      0
                    ),
                  0
                )
                .toFixed(2)}
            </strong>

            <br />
            <strong>
              Sales Volume:{" "}
              {sales
                .filter(
                  (sale) =>
                    sale.status != "pending" && sale.status != "cancelled"
                ) // Only include completed sales
                .reduce(
                  (sum, sale) =>
                    sum +
                    sale.items.reduce(
                      (itemSum, item) =>
                        itemSum + parseFloat(item.quantity || 0),
                      0
                    ),
                  0
                )}
            </strong>

            <br />
            <strong>
              Commission Created:{" "}
              {sales
                .reduce(
                  (sum, sale) =>
                    sum +
                    sale.items.reduce(
                      (itemSum, item) =>
                        itemSum + parseFloat(item.commission || 0),
                      0
                    ),
                  0
                )
                .toFixed(2)}
            </strong>
            <br />
            <strong>
              Commission Earned:{" "}
              {sales
                .filter((sale) => sale.status === "complete") // Only include completed sales
                .reduce(
                  (sum, sale) =>
                    sum +
                    sale.items.reduce(
                      (itemSum, item) =>
                        itemSum + parseFloat(item.commission || 0),
                      0
                    ),
                  0
                )
                .toFixed(2)}
            </strong>
          </div>
        ) : (
          ""
        )}

        <br />
        <SalesQtyList
          sales={sales}
          label={`Calculate Product Quantity ${sales?.length}`}
        />
        <br />
        <List size="lg" hover>
          {sales.map((sale, index) => {
            return (
              <List.Item
                key={index}
                index={index}
                onClick={() => this.props.selectCustomer(sale.customer)}
              >
                <strong>Invoice # {sale.id}</strong> - {sale.created_at} <br />{" "}
                <Tag>
                  {sale.items.length} Items |{" "}
                  {sale?.items?.reduce(
                    (accumulator, currentValue) =>
                      accumulator + parseFloat(currentValue?.quantity),
                    0
                  )}{" "}
                  Pcs |{" "}
                  {sale?.items?.reduce(
                    (accumulator, currentValue) =>
                      accumulator +
                      parseFloat(currentValue?.unit_price) *
                        parseFloat(currentValue?.quantity),
                    0
                  )}{" "}
                  Rupees
                </Tag>
                <br />
                <strong>
                  {sale.customer_id} - {sale.customer_name}{" "}
                  {moment(sale.created_at).isSame(
                    sale.customer_created,
                    "day"
                  ) ? (
                    <Tag size="xs" color="green">
                      &nbsp;New Customer&nbsp;
                    </Tag>
                  ) : (
                    <Tag size="xs" color="orange">
                      &nbsp;Old Customer&nbsp;
                    </Tag>
                  )}
                </strong>
                <br />
                <strong>Status: {sale.status}</strong>
                <br />
              </List.Item>
            );
          })}
        </List>
      </div>
    );
  }
}

export default UserSales;
