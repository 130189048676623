import Cookies from "js-cookie";
import React, { Component } from "react";
import {
  Badge,
  Button,
  InputGroup,
  SelectPicker,
  Nav,
  Pagination,
  Drawer,
  List,
  Tag,
  Modal,
  Divider,
  TagPicker,
  InputNumber,
  Toggle,
  Input,
  Stack,
} from "rsuite";
import constants, { alertError, alertInfoSilent } from "../../constants";
import { loadRoutes } from "../../Loaders/GeneralLoder";
import { IoReloadCircle } from "react-icons/io5";
import FunnelIcon from "@rsuite/icons/Funnel";
import { GoogleMap, MarkerF, PolygonF } from "@react-google-maps/api";
import CustomerView from "../sub-pages/mobile/customer-view";
import CustomerListItem from "../../components/CustomerListItem";
import { getFavCustomers, setFavCustomer } from "../../Helpers/Utilities";
import moment from "moment";
import { getNearestItems } from "../../Helpers/CoordinateHelper";
import AutoCVisits from "../../components/AutoCVisits";
import LoadCustomers from "../../Modules/LoadCustomers";
import BrandIcon from "../../components/BrandIcon";

const favFilterList = ["All", "Favorites Only", "Not Favorites"];
const returnsFilterList = [
  "All",
  "With Sales Return",
  "With Un-Issued Sales Return",
  "Without Sales Return",
];

const sortListItems = [
  "Id",
  "Current Location",
  "Followup Date",
  "Sales Date",
  "Rating",
];

class Shops extends Component {
  state = {
    routes: [],
    customers: [],
    selectedCustomer: null,
    selectedRouteId: null,
    loading: false,
    filterModal: false,
    activeNav: "follow",
    page: 1,
    pageLimit: 15,
    favs: [],
    filters: {
      fav: favFilterList[0],
      salesStatus: constants.salesStatuses.map((item) => item.value),
      salesReturn: returnsFilterList[0],
      sortBy: sortListItems[0],
      sortOrderAsc: true,
      searchTerm: "",
      applyFiltersToFollow: false,
      applySortToFollow: false,
    },
  };

  loading = (loading = true) => this.setState({ loading });

  componentDidMount = () => {
    getFavCustomers((favs) => this.setState({ favs }));
    loadRoutes((routes) =>
      this.setState(
        { routes, selectedRouteId: routes[0]?.id ?? null, page: 1 },
        this.loadCustomers
      )
    );
  };

  loadCustomers = () => {
    var { selectedRouteId } = this.state;
    if (!selectedRouteId) {
      alertError("No route selected..");
      return;
    }
    this.loading();
    fetch(constants.url + "customers/route/" + selectedRouteId, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("u-token")}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({ customers: data.customers });
        this.loading(false);
      })
      .catch((error) => {
        alertError("Failed to load shops..");
        this.loading(false);
      });
  };

  filterCustomers = (customers) => {
    var { filters, favs } = this.state;

    //SEARCH TERM
    if (filters.searchTerm.length != 0) {
      var term = filters.searchTerm.toLowerCase();
      customers = customers.filter(
        (c) =>
          c.person_name?.toLowerCase()?.includes(term) ||
          c.shop_name?.toLowerCase()?.includes(term) ||
          c.phone?.toLowerCase()?.includes(term) ||
          c.email?.toLowerCase()?.includes(term) ||
          c.address?.toLowerCase()?.includes(term) ||
          c.remarks?.toLowerCase()?.includes(term) ||
          c.created_by_name?.toLowerCase()?.includes(term) ||
          c.followups_latest?.remarks?.toLowerCase()?.includes(term)
      );
    }

    if (filters.fav == favFilterList[1]) {
      //with favs only
      customers = customers.filter((c) => favs.includes(c.id));
    } else if (filters.fav == favFilterList[2]) {
      //with no favs only
      customers = customers.filter((c) => !favs.includes(c.id));
    }

    //sales status..
    customers = customers.filter(
      (c) =>
        c.sales_latest && filters.salesStatus.includes(c.sales_latest.status)
    );

    //SALES RETURN FILTERS
    if (filters.salesReturn == returnsFilterList[1]) {
      //with sales return
      customers = customers.filter((c) => c.latest_sales_return != null);
    } else if (filters.salesReturn == returnsFilterList[2]) {
      //with un-issued sales return
      customers = customers.filter(
        (c) =>
          c.latest_sales_return != null &&
          c.latest_sales_return.replace_product == 0
      );
    } else if (filters.salesReturn == returnsFilterList[3]) {
      //without sales return
      customers = customers.filter((c) => c.latest_sales_return == null);
    }

    return customers;
  };

  sortCustomers = (customers) => {
    var { filters } = this.state;

    if (filters.sortBy == sortListItems[1]) {
      //current location
      customers = getNearestItems(
        this.props.currentLocation,
        customers,
        "geo_location",
        customers.length
      );
    } else if (filters.sortBy == sortListItems[2]) {
      //Followup date
      customers = customers.sort((a, b) =>
        a.followups_latest && b.followups_latest
          ? new Date(b.followups_latest.created_at) -
            new Date(a.followups_latest.created_at)
          : a.followups_latest
          ? -1
          : b.followups_latest
          ? 1
          : 0
      );
    } else if (filters.sortBy == sortListItems[3]) {
      //Sales date
      customers = customers.sort((a, b) =>
        a.sales_latest && b.sales_latest
          ? new Date(b.sales_latest.date) - new Date(a.sales_latest.date)
          : a.sales_latest
          ? -1
          : b.sales_latest
          ? 1
          : 0
      );
    } else if (filters.sortBy == sortListItems[4]) {
      //Rating
      customers = customers.sort(
        (a, b) => parseInt(b.rating) - parseInt(a.rating)
      );
    }

    if (!filters.sortOrderAsc) {
      customers = customers.reverse();
    }

    return customers;
  };

  customerList = (paginate = true) => {
    var { customers, page, pageLimit } = this.state;

    customers = this.filterCustomers(customers);
    customers = this.sortCustomers(customers);

    if (paginate) {
      customers = customers?.slice((page - 1) * pageLimit, page * pageLimit);
    }

    return customers;
  };

  eligibleFollowups = (paginate = true) => {
    var { customers, page, pageLimit, filters } = this.state;
    customers = customers
      .filter(
        (customer) =>
          customer.next_followup_date != null &&
          moment(customer.next_followup_date).isSameOrBefore(moment(), "day")
      )
      .sort((a, b) =>
        moment(b.next_followup_date).diff(moment(a.next_followup_date))
      );

    if (filters.applyFiltersToFollow) {
      customers = this.filterCustomers(customers);
    }

    if (filters.applySortToFollow) {
      customers = this.sortCustomers(customers);
    }

    if (paginate) {
      customers = customers?.slice((page - 1) * pageLimit, page * pageLimit);
    }
    return customers;
  };

  changeFilter = (where, what) => {
    var { filters } = this.state;
    filters[where] = what;
    this.setState({ filters, page: 1 });
  };
  render() {
    var { activeNav, selectedRouteId, routes, selectedCustomer, filters } =
      this.state;
    var selectedRoute = routes.find((route) => route.id == selectedRouteId);

    return (
      <div>
        <InputGroup>
          <SelectPicker
            searchable={false}
            size="sm"
            label="Routes"
            data={this.state.routes.map((route) => ({
              label: route.route_name,
              value: route.id,
            }))}
            loading={this.state.loading}
            value={this.state.selectedRouteId}
            onChange={(selectedRouteId) => {
              this.setState({ selectedRouteId }, this.loadCustomers);
            }}
          />
          <Button
            disabled={this.state.loading}
            onClick={this.loadCustomers}
            size="xs"
          >
            <IoReloadCircle />
          </Button>
          <Button
            disabled={this.state.loading}
            size="xs"
            onClick={() => this.setState({ filterModal: true })}
          >
            <FunnelIcon />
          </Button>
        </InputGroup>
        <Nav
          activeKey={activeNav}
          onSelect={(activeNav) => this.setState({ activeNav, page: 1 })}
          style={{ marginBottom: 10 }}
          appearance="tabs"
        >
          <Nav.Item eventKey="follow">
            <Badge
              color="cyan"
              maxCount={9999}
              content={this.eligibleFollowups(false)?.length}
            >
              Followup
            </Badge>
          </Nav.Item>
          <Nav.Item eventKey="all">
            <Badge
              color="cyan"
              maxCount={9999}
              content={this.customerList(false)?.length}
            >
              All
            </Badge>
          </Nav.Item>
          <Nav.Item eventKey="map">Map</Nav.Item>
          <Nav.Item>
            <LoadCustomers
              onLoad={(customers) => this.setState({ customers })}
              route={this.state.selectedRouteId}
              currentLocation={this.props.currentLocation}
              sessionCheck={this.props.sessionCheck}
            />
          </Nav.Item>
        </Nav>

        {activeNav == "follow" && (
          <List hover>
            {this.eligibleFollowups().map((customer, index) => (
              <List.Item>
                <Tag
                  size="sm"
                  color={
                    moment(customer.next_followup_date).isBefore(moment())
                      ? "orange"
                      : "green"
                  }
                  style={{ marginBottom: "10px" }}
                >
                  Next Followup -{" "}
                  {moment(customer.next_followup_date).fromNow()}
                </Tag>
                <CustomerListItem
                  key={customer.id}
                  customer={customer}
                  favs={this.state.favs}
                  onFavUpdate={(favs) => this.setState({ favs })}
                  selectCustomer={() => {
                    console.log(`selecting customer to follow ${customer.id}`);
                    this.setState({ selectedCustomer: customer });
                  }}
                />
              </List.Item>
            ))}
          </List>
        )}

        {activeNav == "all" && (
          <List hover>
            {this.customerList().map((customer, index) => (
              <List.Item>
                <CustomerListItem
                  key={customer.id}
                  customer={customer}
                  favs={this.state.favs}
                  onFavUpdate={(favs) => this.setState({ favs })}
                  selectCustomer={() => {
                    console.log(`selecting customer ${customer.id}`);
                    this.setState({ selectedCustomer: customer });
                  }}
                />
              </List.Item>
            ))}
          </List>
        )}

        {activeNav == "map" && (
          <GoogleMap
            mapContainerStyle={{
              width: "100%",
              height: "68vh",
            }}
            zoom={10}
            onLoad={(mapObject) => {
              this.setState({ mapObject });
              mapObject.setCenter(this.props.currentLocation);
            }}
            onUnmount={() => this.setState({ mapObject: null })}
            mapTypeId="roadmap"
          >
            {selectedRoute && (
              <PolygonF
                options={{
                  paths: JSON.parse(selectedRoute?.cord1).map((coord) => ({
                    lat: parseFloat(coord.lat),
                    lng: parseFloat(coord.lng),
                  })),
                  strokeColor: "#1abc9c",
                  strokeOpacity: 0.8,
                  strokeWeight: 2,
                  fillOpacity: 0,
                }}
              />
            )}

            {this.customerList().map((customer, index) => (
              <MarkerF
                key={`C${index}`}
                position={JSON.parse(customer.geo_location)}
                icon={{
                  url: constants.marker_red,
                  scaledSize: new window.google.maps.Size(32, 32), // specify the size of the icon
                }}
                label={{
                  text: "C",
                  color: "white",
                  fontSize: "9",
                }}
                onClick={() => {
                  console.log(`customer id ${customer.id}`);
                  alertInfoSilent(customer.shop_name + " (View)", 3000, () =>
                    this.setState({ selectedCustomer: customer })
                  );
                  return false;
                }}
                onDblClick={() =>
                  (window.location.href = `https://www.google.com/maps/dir/?api=1&destination=${
                    JSON.parse(customer.geo_location).lat
                  },${
                    JSON.parse(customer.geo_location).lng
                  }&travelmode=motorcycle`)
                }
              />
            ))}

            <MarkerF
              position={this.props.currentLocation}
              label={{
                text: "You",
                fontSize: "10",
              }}
            />
          </GoogleMap>
        )}

        <Pagination
          size="xs"
          total={
            activeNav == "follow"
              ? this.eligibleFollowups(false)?.length
              : this.customerList(false).length
          }
          limit={this.state.pageLimit}
          activePage={this.state.page}
          onChangePage={(page) => {
            this.setState({ page });
            window.scrollTo(0, 0);
            //   document.getElementById("scrollable").scrollTo(0, 0);
          }}
          prev
          last
          next
          first
          maxButtons={5}
        />

        {/* Custoemr View Drawer */}
        {selectedCustomer != null ? (
          <Drawer
            placement="bottom"
            size="full"
            open={selectedCustomer != null}
            onClose={() => this.setState({ selectedCustomer: null })}
          >
            <Drawer.Header>
              <Drawer.Title>
                <BrandIcon />
                View Customer
              </Drawer.Title>
            </Drawer.Header>
            <Drawer.Body>
              <CustomerView
                data={selectedCustomer}
                back={() => this.setState({ selectedCustomer: null })}
                currentLocation={this.props.currentLocation}
                sessionCheck={this.props.sessionCheck}
              />
            </Drawer.Body>
          </Drawer>
        ) : (
          ""
        )}

        {/* Filter modal */}
        <Modal
          open={this.state.filterModal}
          onClose={() => this.setState({ filterModal: false })}
        >
          <Modal.Header>
            <Modal.Title>Filters</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Divider>Search Word</Divider>
            <Input
              value={filters.searchTerm}
              onChange={(value) => this.changeFilter("searchTerm", value)}
            />

            <Divider>Favorites Filter</Divider>
            <SelectPicker
              data={favFilterList.map((f) => ({ label: f, value: f }))}
              searchable={false}
              style={{ width: 224 }}
              value={filters.fav}
              onChange={(value) => this.changeFilter("fav", value)}
            />

            <Divider>Last Sales Status</Divider>
            <TagPicker
              data={constants.salesStatuses}
              value={filters.salesStatus}
              onChange={(value) => this.changeFilter("salesStatus", value)}
              searchable={false}
            />

            <Divider>Sales Return Filter</Divider>
            <SelectPicker
              data={returnsFilterList.map((f) => ({ label: f, value: f }))}
              searchable={false}
              style={{ width: 224 }}
              value={filters.salesReturn}
              onChange={(value) => this.changeFilter("salesReturn", value)}
            />

            <Divider>Sort List Filter</Divider>
            <InputGroup>
              <SelectPicker
                data={sortListItems.map((f) => ({ label: f, value: f }))}
                searchable={false}
                style={{ width: 224 }}
                value={filters.sortBy}
                onChange={(value) => this.changeFilter("sortBy", value)}
              />
              <Toggle
                checked={filters.sortOrderAsc}
                onChange={(value) => this.changeFilter("sortOrderAsc", value)}
                checkedChildren="Ascending"
                unCheckedChildren="Descending"
              />
            </InputGroup>

            <Divider>Pagination</Divider>
            <InputNumber
              value={this.state.pageLimit}
              onChange={(pageLimit) => this.setState({ pageLimit, page: 1 })}
            />

            <Divider>Other</Divider>
            <Stack justifyContent="space-between">
              <i>Apply filters to Follow?</i>
              <Toggle
                checked={filters.applyFiltersToFollow}
                onChange={(value) =>
                  this.changeFilter("applyFiltersToFollow", value)
                }
                checkedChildren="Yes"
                unCheckedChildren="No"
              />
            </Stack>
            <hr />
            <Stack justifyContent="space-between">
              <i>Apply sort (order by) to Follow?</i>
              <Toggle
                checked={filters.applySortToFollow}
                onChange={(value) =>
                  this.changeFilter("applySortToFollow", value)
                }
                checkedChildren="Yes"
                unCheckedChildren="No"
              />
            </Stack>
            <hr />
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default Shops;
