import Cookies from "js-cookie";
import React, { Component } from "react";
import {
  Button,
  DatePicker,
  Divider,
  Drawer,
  Input,
  InputNumber,
  InputPicker,
  Message,
  Modal,
  SelectPicker,
  Stack,
  Tag,
  TagPicker,
  Toggle,
} from "rsuite";
import LeadListItem from "../../components/LeadListItem";
import constants, {
  alertInfoSilent,
  alertError,
  haversineDistance,
  sqlDateTime,
  alertInfo,
} from "../../constants";
import {
  findGeoSpots,
  loadHashTags,
  loadRoutes,
  loadUsersFromRoute,
} from "../../Loaders/GeneralLoder";
import CustomLoader from "../../Widgets/CustomLoader";
import HomeLeadView from "../sub-pages/mobile/home-lead-view";

import {
  DrawingManager,
  GoogleMap,
  Marker,
  MarkerF,
  Polygon,
  PolygonF,
  CircleF,
  useJsApiLoader,
} from "@react-google-maps/api";
import { copyToClipboard, pasteText } from "../../Helpers/Utilities";
import moment from "moment";

class LeadDataManipulation extends Component {
  state = {
    loading: false,
    loadingText: "",
    viewLead: null,
    hashTags: [],
    routes: [],
    route: null,
    routeUsers: [],
    geoSpot: null,
    comparisonRadius: 3,
    dataRadius: 2,
    limitResults: 30,
    lastEventDaysAgo: 30,
    unwantedHashTags: JSON.parse(Cookies.get("unwantedHashTags") ?? "[]"),
    data: null,
    filterShowDataList: false,
    filterShowComparisonList: false,
    filterShowAiGeneratedList: true,
    filterUseAI: false,
    autoFollowAssignedUser: null,
    autoFollowEventDate: null,
    autoFollowAnVisit: false,
  };

  loading = (loading = true, loadingText = "") =>
    this.setState({ loading, loadingText });

  componentDidMount = () => {
    loadHashTags((hashTags) => this.setState({ hashTags }));

    loadRoutes((routes) =>
      this.setState({ routes }, () => this.switchRoute(routes[0]?.id))
    );
  };

  switchRoute = (route) => {
    var { routes } = this.state;

    if (!route) {
      return;
    }

    this.setState({ route, geoSpot: null, geoSpotIndex: null }, () => {
      if (this.state.map) {
        this.state.map.setCenter(JSON.parse(routes[0].cord1)[0]);
      }
    });

    loadUsersFromRoute(route, (routeUsers) => this.setState({ routeUsers }));
  };

  submit = () => {
    var {
      comparisonRadius,
      dataRadius,
      limitResults,
      lastEventDaysAgo,
      geoSpot,
      route,
      unwantedHashTags,
      filterUseAI,
    } = this.state;

    var reqData = {
      comparisonRadius,
      dataRadius,
      limitResults,
      lastEventDaysAgo,
      geoSpot,
      route,
      unwantedHashTags,
      filterUseAI,
    };

    this.loading(true, "Generating Eligible Leads to Visit..");
    fetch(constants.url + `dm-find-geospot-leads`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("u-token")}`,
      },
      body: JSON.stringify(reqData),
    })
      .then((response) => response.json())
      .then((data) => {
        this.loading(false);
        this.setState({ data });
      })
      .catch((error) => {
        alertError("Request failed: " + error?.message);
        this.loading(false);
      });
  };

  autoCreateEvents = () => {
    var {
      autoFollowAssignedUser,
      autoFollowEventDate,
      autoFollowAnVisit,
      data,
    } = this.state;
    if (!autoFollowAssignedUser || !autoFollowEventDate) {
      alertError("AssignedUser and EventDates are important!");
      return;
    }

    var reqData = {
      followup: {
        assigned_user: autoFollowAssignedUser,
        event_date: sqlDateTime(autoFollowEventDate),
        an_visit: autoFollowAnVisit,
      },
      leads: data.filteredLeads,
    };

    this.loading(true, "Creating bulk auto events..");
    fetch(constants.url + "lead_followup-bulk-create-ai", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("u-token")}`,
      },
      body: JSON.stringify(reqData),
    })
      .then((response) => response.json())
      .then((data) => {
        this.loading(false);
        alertInfo("Created BULK Visits.");
      })
      .catch((error) => {
        console.error(error);
        alertError("Unable to create bulk followups");
        this.loading(false);
      });
  };

  render() {
    if (!this.props.sessionCheck.roles.includes("DATA_MANIPULATION_MODULE")) {
      return "";
    }

    var {
      route,
      routes,
      hashTags,
      geoSpot,
      geoSpotIndex,
      data,
      filterShowDataList,
      filterShowComparisonList,
      filterShowAiGeneratedList,
    } = this.state;

    var selectedRoute = routes.find((r) => r.id == route);

    return (
      <div style={{ maxWidth: "100vw", overFlowX: "hidden" }}>
        <CustomLoader
          full
          text={this.state.loadingText}
          loading={this.state.loading}
        />
        <Divider>
          <Tag color="blue">Lead</Tag> Manipulation ({this.state.routes.length}{" "}
          routes)
          {selectedRoute ? (
            <Button
              appearance="link"
              color="yellow"
              onClick={() => {
                this.loading(true, "Updating GeoSpots");
                findGeoSpots(selectedRoute, (data) => {
                  alertInfo("GeoSpots Set");
                  this.setState(({ routes }) => ({
                    routes: routes.map((r) =>
                      r.id === selectedRoute.id ? data.route : r
                    ),
                  }));
                  this.loading(false);
                });
              }}
            >
              Update GeoSpots
            </Button>
          ) : (
            ""
          )}
        </Divider>
        <SelectPicker
          size="sm"
          // searchable={false}
          data={this.state.routes?.map((route) => ({
            label: route.route_name,
            value: route.id,
          }))}
          value={this.state.route}
          onChange={(route) => this.switchRoute(route)}
        />

        {/* Map */}

        <GoogleMap
          mapContainerStyle={{
            width: "100%",
            height: `calc(79vh - ${geoSpot ? "30px" : "0px"} - ${
              data?.filteredLeads ? "30px" : "0px"
            })`,
          }}
          zoom={12}
          onLoad={(map) => {
            this.setState({ map });
            setTimeout(() => map.setCenter(this.props.currentLocation), 300);
          }}
        >
          {selectedRoute ? (
            <>
              <PolygonF
                options={{
                  paths: JSON.parse(selectedRoute.cord1)?.map((coord) => ({
                    lat: parseFloat(coord.lat),
                    lng: parseFloat(coord.lng),
                  })),
                  strokeColor: "#1abc9c",
                  strokeOpacity: 0.8,
                  strokeWeight: 2,
                  fillOpacity: 0,
                }}
              />

              {geoSpot && geoSpotIndex != null && (
                <MarkerF
                  key={
                    geoSpot.lat +
                    "-" +
                    geoSpot.lng +
                    " - selected index" +
                    geoSpotIndex
                  }
                  position={geoSpot}
                  icon={{
                    url: constants.marker_green,
                    scaledSize: new window.google.maps.Size(32, 32), // specify the size of the icon
                  }}
                  label={{
                    text: `${geoSpotIndex + 1}`,
                    color: "black",
                    fontSize: "10",
                  }}
                  onClick={() => this.setState({ geoSpot, geoSpotModal: true })}
                  // onDblClick={() =>
                  //   this.setState({ geoSpot, geoSpotModal: true })
                  // }
                  onRightClick={() => this.setState({ geoSpot: null })}
                />
              )}

              {!geoSpot &&
                selectedRoute.cord3 &&
                JSON.parse(selectedRoute.cord3)?.map((geoSpotThis, index) => (
                  <MarkerF
                    key={`${selectedRoute.id}spot${index}`}
                    position={geoSpotThis}
                    icon={{
                      url: constants.marker_red,
                      scaledSize: new window.google.maps.Size(32, 32), // specify the size of the icon
                    }}
                    label={{
                      text: `${index + 1}`,
                      color: "black",
                      fontSize: "10",
                    }}
                    onClick={() =>
                      this.setState({
                        geoSpot: geoSpotThis,
                        geoSpotIndex: index,
                      })
                    }
                    onDblClick={() =>
                      this.setState({
                        geoSpot: geoSpotThis,
                        geoSpotModal: true,
                      })
                    }
                    onRightClick={() =>
                      alertInfoSilent(
                        `GeoSpot ${index + 1}  has ${
                          geoSpotThis?.count
                        } Customer density, and ${
                          geoSpotThis?.hot_day
                        } is the hottest.`
                      )
                    }
                  />
                ))}

              {filterShowDataList &&
                geoSpot &&
                data &&
                data.dataList?.map((dataLead, index) => (
                  <MarkerF
                    key={`${dataLead.id}dataLead${index}`}
                    position={JSON.parse(dataLead.geolocation)}
                    icon={{
                      url: constants.marker_blue,
                      scaledSize: new window.google.maps.Size(32, 32), // specify the size of the icon
                    }}
                    label={{
                      text: `${dataLead.shop_name}`,
                      color: "black",
                      fontSize: "10",
                    }}
                    onClick={null}
                    onDblClick={() => this.setState({ viewLead: dataLead })}
                  />
                ))}

              {filterShowComparisonList &&
                geoSpot &&
                data &&
                data?.comparisonList?.map((comparisonLead, index) => (
                  <MarkerF
                    key={`${comparisonLead.id}comparisonLead${index}`}
                    position={JSON.parse(comparisonLead.geolocation)}
                    icon={{
                      url: constants.marker_red,
                      scaledSize: new window.google.maps.Size(32, 32), // specify the size of the icon
                    }}
                    label={{
                      text: `${comparisonLead.shop_name}`,
                      color: "black",
                      fontSize: "10",
                    }}
                    onClick={null}
                    onDblClick={() =>
                      this.setState({ viewLead: comparisonLead })
                    }
                  />
                ))}

              {filterShowAiGeneratedList &&
                geoSpot &&
                data &&
                data.filteredLeads?.map(({ lead }, index) => (
                  <MarkerF
                    key={`${lead.id}lead${index}`}
                    position={JSON.parse(lead.geolocation)}
                    icon={{
                      url: constants.marker_pink,
                      scaledSize: new window.google.maps.Size(32, 32), // specify the size of the icon
                    }}
                    label={{
                      text: `${lead.shop_name}`,
                      color: "black",
                      fontSize: "10",
                    }}
                    onClick={null}
                    onDblClick={() => this.setState({ viewLead: lead })}
                  />
                ))}
            </>
          ) : null}

          {geoSpot == null ? (
            ""
          ) : (
            <>
              {/* Clear existing circles by resetting geoSpot before updating */}
              <CircleF
                key={
                  geoSpot.lat + "-" + geoSpot.lng + "-comparison" // Add timestamp to force re-render
                }
                center={geoSpot}
                radius={this.state.comparisonRadius * 1000}
                options={{
                  fillOpacity: 0,
                  strokeColor: "#4A90E2",
                  strokeOpacity: 0.8,
                  strokeWeight: 2,
                }}
              />

              <CircleF
                key={
                  geoSpot.lat + "-" + geoSpot.lng + "-data" // Add timestamp to force re-render
                }
                center={geoSpot}
                radius={this.state.dataRadius * 1000}
                options={{
                  fillOpacity: 0,
                  strokeColor: "#ADD8E6",
                  strokeOpacity: 0.8,
                  strokeWeight: 2,
                }}
              />
            </>
          )}
        </GoogleMap>
        <br />
        {geoSpot ? (
          <Button
            appearance="link"
            onClick={() => this.setState({ geoSpotModal: true })}
          >
            Open GeoSpot Window ({geoSpotIndex + 1})
          </Button>
        ) : (
          ""
        )}
        {filterShowAiGeneratedList && data?.filteredLeads && (
          <Button
            block
            onClick={() => this.setState({ filteredLeadsModal: true })}
          >
            {" "}
            View AI GeneratedLeads List ({data?.filteredLeads?.length})
          </Button>
        )}

        {/* List item model */}
        <Modal
          open={this.state.filteredLeadsModal}
          onClose={() => this.setState({ filteredLeadsModal: null })}
        >
          <Modal.Header>
            <Modal.Title>
              Display AI Generated Leads ({data?.filteredLeads?.length})
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {data?.filteredLeads?.map((item, index) => (
              <>
                <div
                  style={{
                    width: "97%",
                    padding: "3px",
                    background: "#ededed",
                    color: "gray",
                    marginTop: "7px",
                  }}
                >
                  <strong>{item.probability} chances to close:</strong> &nbsp;{" "}
                  {item.reason}
                </div>
                <LeadListItem
                  lead={item?.lead}
                  onHideLead={() => {
                    data.filteredLeads = data.filteredLeads.filter(
                      (i) => i.lead.id !== item?.lead?.id
                    );
                    this.setState({ data });
                  }}
                  key={item?.lead?.id}
                  onClick={() => {
                    this.setState({ viewLead: item?.lead });
                  }}
                  hashTags={this.state.hashTags}
                />
              </>
            ))}

            <br />
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => this.setState({ followupFormModal: true })}
              appearance="primary"
            >
              AutoCreate Events/Visits
            </Button>

            {/*
            <Button onClick={handleClose} appearance="subtle">
              Cancel
            </Button> */}
          </Modal.Footer>
        </Modal>

        {/* followup form modal */}
        <Modal
          open={this.state.followupFormModal}
          onClose={() => this.setState({ followupFormModal: null })}
        >
          <Modal.Header>
            <Modal.Title>Auto Create Events / Visits</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <strong>Assigned User:</strong>
            <InputPicker
              value={this.state.autoFollowAssignedUser}
              onChange={(autoFollowAssignedUser) =>
                this.setState({ autoFollowAssignedUser })
              }
              data={this.state.routeUsers?.map((i) => ({
                label: i.name,
                value: i.id,
              }))}
              placeholder="assigned_user"
              block
            />
            <br />

            <strong>Event Date</strong>
            <DatePicker
              placement="auto"
              block
              value={
                this.state.autoFollowEventDate
                  ? moment(this.state.autoFollowEventDate).toDate()
                  : null
              }
              onChange={(autoFollowEventDate) =>
                this.setState({ autoFollowEventDate })
              }
              format="yyyy-MM-dd HH:mm"
              editable={false}
              oneTap
            />

            <br />
            <Stack justifyContent="space-between">
              <span>Afternoon-visit?</span>

              <Toggle
                checked={this.state.autoFollowAnVisit}
                checkedChildren="Yes"
                unCheckedChildren="No"
                onChange={(autoFollowAnVisit) =>
                  this.setState({ autoFollowAnVisit })
                }
              />
            </Stack>
            <br />
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.autoCreateEvents} appearance="primary">
              AutoCreate Events/Visits
            </Button>

            {/*
            <Button onClick={handleClose} appearance="subtle">
              Cancel
            </Button> */}
          </Modal.Footer>
        </Modal>

        {/* SELECTED GEOSPOT MODAL */}
        <Modal
          open={this.state.geoSpotModal}
          onClose={() => this.setState({ geoSpotModal: null })}
        >
          <Modal.Header>
            <Modal.Title>
              Selected GeoSpot has {geoSpot?.count} customer density{" "}
              {geoSpot?.hot_day ? (
                <Tag
                  color="red"
                  onClick={() => alert(JSON.stringify(geoSpot?.sales_by_day))}
                >
                  {geoSpot?.hot_day} is the Hottest
                </Tag>
              ) : (
                ""
              )}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Divider>Find Leads Here</Divider>
            <i>Comparison Radius in KM</i>
            <InputNumber
              value={this.state.comparisonRadius}
              placeholder="comparisonRadius"
              onChange={(comparisonRadius) =>
                this.setState({ comparisonRadius })
              }
            />
            <i>Data Radius Radius in KM</i>
            <InputNumber
              value={this.state.dataRadius}
              placeholder="dataRadius"
              onChange={(dataRadius) => this.setState({ dataRadius })}
            />
            <i>Limit Result</i>
            <InputNumber
              value={this.state.limitResults}
              placeholder="limitResults"
              onChange={(limitResults) => this.setState({ limitResults })}
            />
            <i>Last Event #days Ago</i>
            <InputNumber
              value={this.state.lastEventDaysAgo}
              placeholder="lastEventDaysAgo"
              onChange={(lastEventDaysAgo) =>
                this.setState({ lastEventDaysAgo })
              }
            />
            <i>
              Unwanted #HashTags
              <Button
                appearance="link"
                color="orange"
                onClick={() =>
                  copyToClipboard(JSON.stringify(this.state.unwantedHashTags))
                }
                size="sm"
                style={{ marginLeft: "5px" }}
              >
                copy
              </Button>{" "}
              |{" "}
              <Button
                appearance="link"
                color="blue"
                onClick={() =>
                  pasteText((text) =>
                    this.setState({ unwantedHashTags: JSON.parse(text) })
                  )
                }
                size="sm"
                style={{ marginLeft: "5px" }}
              >
                paste
              </Button>
            </i>
            <TagPicker
              data={this.state.hashTags?.map((item) => ({
                label: item.name,
                value: item.name,
              }))}
              block
              placeholder="unwanted #tags"
              onChange={(unwantedHashTags) => {
                this.setState({ unwantedHashTags });
                Cookies.set(
                  "unwantedHashTags",
                  JSON.stringify(unwantedHashTags)
                );
              }}
              value={this.state.unwantedHashTags}
            />

            {data && (
              <div>
                <Divider>Visibility Filters</Divider>

                <Stack justifyContent="space-between">
                  <strong>DataList ({data.dataList?.length} Leads)</strong>
                  <Toggle
                    size="xs"
                    checked={this.state.filterShowDataList}
                    checkedChildren="Showing"
                    unCheckedChildren="Hidden"
                    onChange={(filterShowDataList) => {
                      this.setState({ filterShowDataList });
                    }}
                  />
                </Stack>
                <br />
                <Stack justifyContent="space-between">
                  <strong>
                    ComparisonList ({data.comparisonList?.length} Customers)
                  </strong>
                  <Toggle
                    size="xs"
                    checked={this.state.filterShowComparisonList}
                    checkedChildren="Showing"
                    unCheckedChildren="Hidden"
                    onChange={(filterShowComparisonList) => {
                      this.setState({ filterShowComparisonList });
                    }}
                  />
                </Stack>
                <br />
                <Stack justifyContent="space-between">
                  <strong>
                    AI Generated List ({data.filteredLeads?.length} Leads)
                  </strong>
                  <Toggle
                    size="xs"
                    checked={this.state.filterShowAiGeneratedList}
                    checkedChildren="Showing"
                    unCheckedChildren="Hidden"
                    onChange={(filterShowAiGeneratedList) => {
                      this.setState({ filterShowAiGeneratedList });
                    }}
                  />
                </Stack>
                <br />
                <br />
                {this.state.filterUseAI && data?.whatLearned ? (
                  <Message>
                    <strong>AI Learned: </strong> {data?.whatLearned}
                  </Message>
                ) : (
                  ""
                )}
              </div>
            )}
          </Modal.Body>
          <Modal.Footer
            style={{ background: "black", color: "white", padding: "4px" }}
          >
            <Stack justifyContent="space-between">
              <Toggle
                checked={this.state.filterUseAI}
                checkedChildren="Use Stack AI"
                unCheckedChildren="Use Stack AI"
                onChange={(filterUseAI) => this.setState({ filterUseAI })}
              />
              <div>
                <Button onClick={this.submit} appearance="ghost" color="red">
                  SUBMIT
                </Button>

                <Button
                  onClick={() =>
                    this.setState({ geoSpot: null, geoSpotModal: false })
                  }
                  appearance="ghost"
                  color="yellow"
                >
                  CLOSE SPOT
                </Button>
              </div>
            </Stack>
          </Modal.Footer>
        </Modal>

        {/* Lead display modal */}

        {this.state.viewLead != null && (
          <Drawer
            open={this.state.viewLead != null}
            onClose={() => this.setState({ viewLead: null })}
            placement="bottom"
            size="full"
          >
            <Drawer.Header>
              <Drawer.Title>
                View Lead - {this.state.viewLead?.shop_name}
              </Drawer.Title>
            </Drawer.Header>
            <Drawer.Body>
              <HomeLeadView
                lead={this.state.viewLead}
                back={() => this.setState({ viewLead: null })}
                currentLocation={this.props.currentLocation}
                sessionCheck={this.props.sessionCheck}
                setView={(selectedLead) => this.setState({ selectedLead })}
                routes={this.state.routes}
                hashTags={this.state.hashTags}
                loadLeads={() => {}}
              />
            </Drawer.Body>
          </Drawer>
        )}
      </div>
    );
  }
}

export default LeadDataManipulation;
