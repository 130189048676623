import React, { Component } from "react";
import {
  Affix,
  Button,
  Divider,
  Drawer,
  Input,
  InputGroup,
  List,
} from "rsuite";
import SearchIcon from "@rsuite/icons/Search";
import BrandIcon from "../components/BrandIcon";
import Cookies from "js-cookie";
import constants, { alertError } from "../constants";
import { bulkCVisitCreate } from "../Loaders/GeneralSaver";
import CustomerListItem from "../components/CustomerListItem";
import CustomLoader from "../Widgets/CustomLoader";
import CustomerView from "../Pages/sub-pages/mobile/customer-view";
import StackAssistant from "./GoogleAI/StackAssistant";

class LoadCustomers extends Component {
  state = {
    drawer: false,
    customers: [],
    selectedCustomer: null,
    loading: false,
  };

  loading = (loading = true) => this.setState({ loading });

  submit = () => {
    var { inputText } = this.state;
    if (!inputText || inputText.length == 0) {
      return;
    }

    this.loading();

    //here input text is a list of ids entered as text format, it could be seperated by space, comma, or sometimes line breaks, need to parse these ids (numerical) as an array
    // Parse the inputText into an array of numerical IDs
    let ids = [];

    try {
      // Check if inputText is a valid JSON array
      if (inputText.startsWith("[")) {
        // Attempt to parse the inputText as JSON
        const parsedArray = JSON.parse(inputText);

        // Ensure the parsed result is an array
        if (Array.isArray(parsedArray)) {
          ids = parsedArray
            .map((id) =>
              typeof id === "string" ? id.trim() : id.toString().trim()
            ) // Trim and convert to string
            .filter((id) => id.length > 0) // Filter out empty strings
            .map(Number) // Convert to numbers
            .filter((id) => !isNaN(id)) // Filter out any non-numerical values
            .filter((id, index, self) => self.indexOf(id) === index); // Remove duplicates
        }
      } else {
        // Handle the case where inputText is not a JSON array
        ids = inputText
          .replace(/\n/g, ",") // Replace line breaks with commas
          .split(/[\s,]+/) // Split by spaces or commas
          .map((id) => id.trim()) // Trim any extra whitespace
          .filter((id) => id.length > 0) // Filter out empty strings
          .map(Number) // Convert to numbers
          .filter((id) => !isNaN(id)) // Filter out any non-numerical values
          .filter((id, index, self) => self.indexOf(id) === index); // Remove duplicates
      }
    } catch (error) {
      console.error("Invalid input format:", error);
      // Handle the error (e.g., set ids to an empty array or show an error message)
      ids = [];
    }

    this.setState({ inputText: JSON.stringify(ids) });

    fetch(constants.url + "load-customers", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("u-token")}`,
      },
      body: JSON.stringify({ ids, route: this.props.route }),
    })
      .then((response) => response.json())
      .then((data) => {
        this.loading(false);
        this.setState({ customers: data?.customers ?? [] });
        this.props.onLoad(data?.customers ?? []);
      })
      .catch((error) => {
        alertError("Unable to load..");
        this.loading(false);
        console.error(error);
      });
  };
  render() {
    var { customers, selectedCustomer } = this.state;
    return (
      <>
        <CustomLoader full text="Loading.." loading={this.state.loading} />
        <Button
          size="sm"
          appearance={customers.length > 0 ? "primary" : ""}
          onClick={() => this.setState({ drawer: true })}
        >
          Load-C
        </Button>
        <Drawer
          size="full"
          open={this.state.drawer}
          onClose={() => this.setState({ drawer: false })}
        >
          <Drawer.Header>
            <Drawer.Title>
              <BrandIcon /> Customer Loader
            </Drawer.Title>
            {/* <Drawer.Actions>
                  <Button onClick={() => setOpen(false)}>Cancel</Button>
                  <Button onClick={() => setOpen(false)} appearance="primary">
                    Load
                  </Button>
                </Drawer.Actions> */}
          </Drawer.Header>
          <Drawer.Body>
            <Affix>
              <InputGroup>
                <Input
                  value={this.state.inputText}
                  as="textarea"
                  rows={3}
                  placeholder="Paste IDs"
                  onChange={(inputText) => this.setState({ inputText })}
                />
                <InputGroup.Button
                  onClick={this.submit}
                  disabled={this.state.loading}
                >
                  <SearchIcon />
                </InputGroup.Button>
              </InputGroup>
              <Divider>
                Customers {customers.length}{" "}
                {customers.length > 0 ? (
                  <Button
                    appearance="link"
                    onClick={() => {
                      this.loading();
                      bulkCVisitCreate(customers, () => this.loading(false));
                    }}
                  >
                    Create CVisits
                  </Button>
                ) : (
                  ""
                )}
              </Divider>
            </Affix>
            <StackAssistant
              sessionCheck={this.props.sessionCheck}
              currentLocation={this.props.currentLocation}
            />
            <br />
            <List hover>
              {customers.map((customer, index) => (
                <List.Item key={`customer-load-${customer.id}`}>
                  <Button
                    block
                    onClick={() =>
                      this.setState({
                        customers: customers.filter(
                          (c) => c.id !== customer.id
                        ),
                      })
                    }
                  >
                    CLOSE (X)
                  </Button>
                  <CustomerListItem
                    customer={customer}
                    favs={[]}
                    selectCustomer={() => {
                      this.setState({ selectedCustomer: customer });
                    }}
                  />
                </List.Item>
              ))}
            </List>
          </Drawer.Body>
        </Drawer>

        {/* customer view */}
        {selectedCustomer != null ? (
          <Drawer
            placement="bottom"
            size="full"
            open={selectedCustomer != null}
            onClose={() => this.setState({ selectedCustomer: null })}
          >
            <Drawer.Header>
              <Drawer.Title>
                <BrandIcon />
                View Customer
              </Drawer.Title>
            </Drawer.Header>
            <Drawer.Body>
              <CustomerView
                data={selectedCustomer}
                back={() => this.setState({ selectedCustomer: null })}
                currentLocation={this.props.currentLocation}
                sessionCheck={this.props.sessionCheck}
              />
            </Drawer.Body>
          </Drawer>
        ) : (
          ""
        )}
      </>
    );
  }
}

export default LoadCustomers;
