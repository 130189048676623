import React, { Component } from "react";
import { Drawer, Input, Button, Stack, Toggle } from "rsuite";
import { GiRobotHelmet } from "react-icons/gi";
import { IoSend } from "react-icons/io5";
import ReactMarkdown from "react-markdown";

import "./assistant-styles.css";
import { generateText, handleConversation } from "./AiHelper";
import { copyToClipboard } from "../../Helpers/Utilities";

class StackAssistant extends Component {
  state = {
    drawer: false,
    inputText: "",
    chats: [],
    loading: false,
    tutor: false,
  };

  loading = (loading = true) => this.setState({ loading });

  send = () => {
    const { inputText, chats, tutor } = this.state;

    // Prevent sending empty or invalid input
    if (!inputText || inputText.trim().length < 2) return;

    const userName = this.props.sessionCheck?.user?.name || "User";
    const userRole =
      this.props.sessionCheck?.active_employment?.employment_type?.name ||
      "Employee";

    const systemInstruction = `You are Stack AI - Assistant. My name is ${userName}, and my role at SalesMart is ${userRole}. As Stack AI, your main objective is to provide intelligent, timely assistance to employees working with SalesMart, ensuring seamless operations within the platform. You are trained to help with tasks such as customer support, lead management, inventory tracking, and report generation, all tailored to SalesMart's ecosystem. Always ensure that you respond in a helpful, clear, and professional manner, maintaining the tone and context relevant to the task at hand.`;

    this.setState(
      {
        loading: true,
        chats: [...chats, { role: "user", parts: [{ text: inputText }] }],
        inputText: "",
      },
      () => {
        this.scrollToBottom();
        handleConversation(
          this.state.chats,
          systemInstruction,
          tutor,
          (response) => {
            // Extract the chat_text from the response
            const reply = response?.data?.chat_text || null;

            if (reply) {
              this.setState(
                {
                  chats: [
                    ...this.state.chats,
                    { role: "model", parts: [{ text: reply }] }, // AI's response
                  ],
                  loading: false,
                },
                () => {
                  this.scrollToBottom();
                }
              );
            } else {
              this.setState({ loading: false });
            }
          }
        );
      }
    );
  };

  scrollToBottom = () => {
    const scrollable = document.getElementById("scrollable");
    if (scrollable) {
      scrollable.scrollTop = scrollable.scrollHeight;
    }
  };

  render() {
    return (
      <>
        <Button
          appearance="primary"
          color="green"
          block
          style={{ width: "80%", display: "flex", margin: "auto" }}
          onClick={() => this.setState({ drawer: true })}
        >
          <GiRobotHelmet /> Stack AI
        </Button>
        <Drawer
          size="full"
          open={this.state.drawer}
          onClose={() => this.setState({ drawer: false })}
        >
          <Drawer.Header>
            <Drawer.Title>
              <Stack justifyContent="space-between">
                <strong>
                  <GiRobotHelmet /> Stack AI - Assistant
                </strong>

                <div>
                  <Button
                    onClick={() => {
                      this.setState({ inputText: "", chats: [] });
                    }}
                  >
                    Clear
                  </Button>
                  &nbsp;
                  {this.props.sessionCheck.roles.includes("AI_TUTOR") ? (
                    <Toggle
                      size="xs"
                      checked={this.state.tutor}
                      checkedChildren="Tutor"
                      unCheckedChildren="Tutor"
                      onChange={(tutor) => {
                        this.setState({ tutor });
                      }}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </Stack>
            </Drawer.Title>
          </Drawer.Header>
          <Drawer.Body>
            <div id="scrollable" className="chat-body">
              {this.state.chats.map(
                (chat, index) =>
                  chat.parts && chat.parts.length > 0 ? (
                    <div
                      key={index}
                      className={`chat-message ${
                        chat.role === "user" ? "chat-me" : "chat-ai"
                      }`}
                    >
                      <span className="chat-label">
                        {chat.role === "user"
                          ? this.props.sessionCheck?.user?.name
                          : "StackAI"}
                      </span>

                      {chat.role === "user" ? (
                        <p className="chat-text">{chat.parts[0].text}</p>
                      ) : (
                        <>
                          <ReactMarkdown className="chat-text">
                            {chat.parts[0].text}
                          </ReactMarkdown>
                          <Button
                            appearance="link"
                            style={{ margin: "5px" }}
                            onClick={() => copyToClipboard(chat.parts[0].text)}
                          >
                            copy
                          </Button>
                        </>
                      )}
                    </div>
                  ) : null // Use `null` instead of an empty string to avoid rendering unnecessary elements
              )}

              {this.state.loading ? (
                <div className={`chat-message chat-ai`}>
                  <span className="chat-label">StackAI</span>

                  <ReactMarkdown className="chat-text">Typing...</ReactMarkdown>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="chat-footer">
              <Input
                value={this.state.inputText}
                onChange={(value) => this.setState({ inputText: value })}
                className="chat-input"
                placeholder="Type a message..."
                disabled={this.state.loading}
              />
              <Button
                onClick={this.send}
                appearance="primary"
                className="send-button"
                disabled={this.state.loading}
              >
                <IoSend />
              </Button>
            </div>
          </Drawer.Body>
        </Drawer>
      </>
    );
  }
}

export default StackAssistant;
