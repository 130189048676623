import React, { Component } from "react";
import { Loader } from "rsuite";

const styles = {
  width: "100vw",
  height: "100vh",
  position: "absolute",
  left: 0,
  top: 0,
  zIndex: "999999",
  background: "#fefefe80",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

class CustomLoader extends Component {
  state = { forceStop: false };
  render() {
    var { loading, text, full } = this.props;
    if (this.state.forceStop || !loading) {
      return <></>;
    }

    return (
      <div style={full ? styles : {}}>
        <Loader
          content={text ?? "Loading data..."}
          vertical
          center
          onDblClick={() =>
            this.setState({ forceStop: true }, () =>
              setTimeout(() => this.setState({ forceStop: false }), 10000)
            )
          }
        />
      </div>
    );
  }
}

export default CustomLoader;
