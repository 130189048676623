import React, { Component } from "react";
import {
  DrawingManager,
  GoogleMap,
  Marker,
  MarkerF,
  Polygon,
  PolygonF,
  useJsApiLoader,
} from "@react-google-maps/api";
import Cookies from "js-cookie";
import constants, {
  alertError,
  alertInfo,
  alertInfoSilent,
} from "../../../constants";
import {
  Button,
  Checkbox,
  InputPicker,
  Loader,
  CheckPicker,
  Stack,
  Divider,
} from "rsuite";
import { findGeoSpots } from "../../../Loaders/GeneralLoder";

const colors = [
  "#1abc9c",
  "#2ecc71",
  "#3498db",
  "#9b59b6",
  "#34495e",
  "#16a085",
  "#27ae60",
  "#2980b9",
  "#8e44ad",
  "#2c3e50",
  "#f1c40f",
  "#e67e22",
  "#e74c3c",
  "#d35400",
  "#c0392b",
  "#7f8c8d",
  "#f39c12",
  "#d35400",
  "#d35400",
  "#d35400",
  "#d35400",
  "#d35400",
  "#d35400",
  "#d35400",
  "#d35400",
  "#d35400",
  "#d35400",
  "#d35400",
  "#d35400",
  "#d35400",
  "#d35400",
  "#d35400",
  "#d35400",
  "#d35400",
  "#d35400",
  "#d35400",
  "#d35400",
  "#d35400",
  "#d35400",
  "#d35400",
  "#d35400",
  "#d35400",
  "#d35400",
  "#d35400",
  "#d35400",
  "#d35400",
  "#d35400",
  "#d35400",
  "#d35400",
  "#d35400",
  "#d35400",
  "#d35400",
];

const plainRoute = [
  { lat: 8.80854775216947, lng: 76.46894391324591 },
  { lat: 8.83733829853632, lng: 76.58742747750198 },
  { lat: 8.753199633478067, lng: 76.6139729755047 },
  { lat: 8.745840591891765, lng: 76.49030980188225 },
];

const containerStyle = {
  width: "100%",
  height: "400px",
};

const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

class RouteManager extends Component {
  state = {
    routes: null,
    selected: null,
    loading: false,
    editable: false,
    map: null,
    days: [],
  };

  componentDidMount = () => {
    this.loadRoutes();
  };

  setLoading = (loading = true) => {
    this.setState({ loading });
  };

  loadRoutes = () => {
    this.setState({ routes: null });
    this.setLoading();
    fetch(constants.url + "routes", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("u-token")}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        this.setState({ routes: data.routes });
        this.setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        this.setLoading(false);
      });
  };

  handlePolygonChange = (polygon) => {
    console.log("polygon change");
    // The event object may contain information about the edited polygon
    // You can access the new polygon paths using event.getPath()
    try {
      // The event object may contain information about the edited polygon
      // You can access the new polygon paths using event.getPath()
      const newCoordinates = polygon
        .getPath()
        .getArray()
        .map((latLng) => ({ lat: latLng.lat(), lng: latLng.lng() }));

      var { routes, selected } = this.state;

      routes[selected].cord1 = JSON.stringify(newCoordinates);
      this.setState({ routes });
    } catch (error) {
      alertError("Polygon drag failed..");
      console.error("Error parsing coordinates:", error);
    }
  };

  saveSelected = () => {
    var { routes, selected } = this.state;
    var data = routes[selected];
    this.setLoading();
    fetch(constants.url + `routes/${data.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("u-token")}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((res) => {
        alertInfo("Route boundaries updated");
        this.loadRoutes();
        this.setLoading(false);
      })
      .catch((error) => {
        alertError("Unable to update route.");
        this.setLoading(false);
      });
  };

  makePlainSelected = () => {
    var conf = window.confirm(
      "Are you sure to reset the boundaries of this route??"
    );

    var conf2 = window.confirm("Again you sure?");
    if (!conf || !conf2) {
      return;
    }
    var { routes, selected } = this.state;
    routes[selected].cord1 = JSON.stringify(plainRoute);
    this.setState({ routes });
  };

  changeSelected = (where, what) => {
    var { routes } = this.state;
    routes[this.state.selected][where] = what;
    this.setState({ routes });
  };

  render() {
    if (this.state.routes == null) {
      return <Loader />;
    }
    const { routes, selected, editable } = this.state;

    let coordinates = [];
    if (routes[selected]?.cord1) {
      try {
        coordinates = JSON.parse(routes[selected]?.cord1);
      } catch (error) {
        console.error("Error parsing coordinates:", error);
      }
    }

    return (
      <div style={{ width: "100vw !important" }}>
        <Stack>
          <InputPicker
            placeholder="Select route"
            value={selected}
            data={routes?.map((item, index) => ({
              label: item.route_name,
              value: index,
            }))}
            onChange={(selected) => this.setState({ selected })}
            disabled={this.state.loading}
          />
        </Stack>

        {coordinates && <h3>Points: {coordinates.length}</h3>}
        {selected != null && (
          <>
            <i>Days for delivery (VALUE SET ON CORD2 COLUMN)</i>
            <br />
            <CheckPicker
              data={days.map((item) => ({ label: item, value: item }))}
              value={JSON.parse(routes[selected]?.cord2 ?? "[]")}
              onChange={(value) =>
                this.changeSelected("cord2", JSON.stringify(value))
              }
            />
            <br />
            <Button
              appearance="link"
              onClick={() => this.setState({ editBoundaries: true })}
            >
              Edit Boundaries
            </Button>
            <br />
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={this.props.initialLocation}
              zoom={12}
              onLoad={(map) => {
                this.setState({ map });
                window.map = map;
                map.setCenter(plainRoute[1]);
              }}
            >
              {routes.map(
                (route, index) =>
                  route.cord1 &&
                  route.cord1.length > 2 && (
                    <>
                      <PolygonF
                        key={index} // Add a unique key to each polygon
                        options={{
                          paths: JSON.parse(route.cord1).map((coord) => ({
                            lat: parseFloat(coord.lat),
                            lng: parseFloat(coord.lng),
                          })),
                          strokeColor: colors[index],
                          strokeOpacity: 0.8,
                          strokeWeight: 2,
                          fillColor: colors[index],
                          fillOpacity: 0.2,
                          editable:
                            this.state.editBoundaries &&
                            routes[selected]?.id === route.id,
                        }}
                        onEdit={(e) => this.handlePolygonChange(e)}
                      />

                      {}

                      {routes[selected]?.id === route.id &&
                        route.cord3 &&
                        JSON.parse(route.cord3)?.map((geoSpot, index) => (
                          <MarkerF
                            key={`spot${index}`}
                            position={geoSpot}
                            icon={{
                              url: constants.marker_red,
                              scaledSize: new window.google.maps.Size(32, 32), // specify the size of the icon
                            }}
                            label={{
                              text: `${index + 1}`,
                              color: "black",
                              fontSize: "10",
                            }}
                            onClick={() =>
                              alertInfoSilent(
                                `Rank ${index + 1} - ${geoSpot?.count}`
                              )
                            }
                          />
                        ))}
                    </>
                  )
              )}
            </GoogleMap>
          </>
        )}

        <br />
        {selected != null && (
          <div>
            <Button
              appearance="primary"
              color="green"
              block
              onClick={this.saveSelected}
            >
              Save {routes[selected]?.route_name}
            </Button>
            <br />
            <br />
            <br />
            {this.state.editBoundaries ? (
              <Button
                appearance="primary"
                color="red"
                block
                onClick={this.makePlainSelected}
              >
                Reset Boundaries of {routes[selected]?.route_name}
              </Button>
            ) : null}
            <br />
            <Divider>Find GeoSpots of {routes[selected]?.route_name}</Divider>
            <Button
              appearance="primary"
              color="yellow"
              block
              onClick={() =>
                findGeoSpots(routes[selected], (data) => {
                  alertInfo("GeoSpots Set");
                  routes[selected] = data.route;
                })
              }
            >
              FIND GEOSPOTS
            </Button>
          </div>
        )}
      </div>
    );
  }
}

export default RouteManager;
