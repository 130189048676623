import React, { Component } from "react";
import { FaSearchengin } from "react-icons/fa6";
import { FaSearch } from "react-icons/fa";

import {
  Button,
  Divider,
  Drawer,
  Input,
  InputGroup,
  List,
  Loader,
  Modal,
  Stack,
  Tag,
} from "rsuite";
import Cookies from "js-cookie";
import constants, { alertError, haversineDistance } from "../constants";
import CustomerView from "../Pages/sub-pages/mobile/customer-view";
import HomeLeadView from "../Pages/sub-pages/mobile/home-lead-view";
import { loadHashTags, loadRoutes } from "../Loaders/GeneralLoder";
import { checkRoute } from "../Helpers/RouteHelper";
import QuickCreateLead from "./QuickCreateLead";
import {
  GoogleMap,
  MarkerF,
  PolygonF,
  PolylineF,
} from "@react-google-maps/api";
import { getRouteZones } from "../Helpers/CoordinateHelper";
import { getFavCustomers } from "../Helpers/Utilities";
import CustomerListItem from "../components/CustomerListItem";
import LeadListItem from "../components/LeadListItem";
import MapCVisits from "../Widgets/MagWidgets/MapCVisits";
import MapActionBar from "../Widgets/MagWidgets/MapActionBar";

class QuickFinder extends Component {
  state = {
    modal: false,
    loading: false,
    searchValue: "",
    selectedLead: null,
    selectedCustomer: null,
    routes: [],
    map: null,
    showList: null,
  };

  componentDidMount = () => {
    loadRoutes((routes) => this.setState({ routes }));
  };

  setLoading = (loading = true) => this.setState({ loading });

  modal = (modal = true) => this.setState({ modal, showList: null });

  findNearest = () => {
    var route = checkRoute(this.props.currentLocation, this.state.routes);
    this.setLoading();
    fetch(constants.url + "find-nearest", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("u-token")}`,
      },
      body: JSON.stringify({
        location: this.state.map?.getCenter() || this.props.currentLocation,
        route_id: route?.id ?? null,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({ data });
        this.setLoading(false);
      })
      .catch((error) => {
        alertError("Unable to find nearest");
        console.error(error);
        this.setLoading(false);
      });
  };

  search = () => {
    var { searchValue } = this.state;
    this.setLoading();
    fetch(constants.url + "search/" + searchValue, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("u-token")}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({ data });
        this.setLoading(false);
      })
      .catch((error) => {
        alertError("Unable to find nearest");
        console.error(error);
        this.setLoading(false);
      });
  };

  showList = (showListNew = null) => {
    var { showList } = this.state;

    this.setState({ showList: showList == showListNew ? null : showListNew });
  };
  render() {
    var { selectedCustomer, selectedLead } = this.state;
    return (
      <>
        <Button onClick={this.modal}>
          <FaSearchengin />
          {this.props.buttonText ?? ""}
        </Button>
        {this.state.modal && (
          <Modal open={this.state.modal} onClose={() => this.modal(false)}>
            <Modal.Header>
              <Modal.Title>
                {this.state.loading ? (
                  <Loader sie="md" content="Searching.." />
                ) : (
                  "Quick Finder"
                )}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {/* <Divider>
                Customers {this.state.data?.customers?.length ?? 0}
              </Divider>
              <List hover>
                {this.state.data?.customers?.map((customer, index) => (
                  <List.Item
                    key={`c${index}`}
                    onClick={() =>
                      this.setState({
                        selectedCustomer: customer,
                        modal: false,
                      })
                    }
                  >
                    <Stack justifyContent="space-between">
                      <strong>{customer?.shop_name}</strong>
                      <Tag>
                        {(
                          haversineDistance(
                            JSON.parse(customer.geo_location),
                            this.props.currentLocation
                          ) / 1000
                        ).toFixed(2)}{" "}
                        KM away
                      </Tag>
                    </Stack>
                  </List.Item>
                ))}
              </List>
              <Divider>Leads {this.state.data?.leads?.length ?? 0}</Divider>
              <List hover>
                {this.state.data?.leads?.map((lead, index) => (
                  <List.Item
                    key={`l${index}`}
                    onClick={() =>
                      this.setState({ selectedLead: lead, modal: false })
                    }
                  >
                    <Stack justifyContent="space-between">
                      <strong>{lead?.shop_name}</strong>
                      <Tag>
                        {(
                          haversineDistance(
                            JSON.parse(lead.geolocation),
                            this.props.currentLocation
                          ) / 1000
                        ).toFixed(2)}{" "}
                        KM away
                      </Tag>
                    </Stack>
                  </List.Item>
                ))}
              </List> */}

              <Divider>
                <Button
                  appearance="link"
                  color={this.state.showList == "customers" ? "green" : "blue"}
                  onClick={() => this.showList("customers")}
                >
                  Customers {this.state.data?.customers?.length ?? 0}
                </Button>
                |
                <Button
                  appearance="link"
                  color={this.state.showList == "leads" ? "green" : "blue"}
                  onClick={() => this.showList("leads")}
                >
                  Leads {this.state.data?.leads?.length ?? 0}
                </Button>
              </Divider>

              {/* show customers list ..  */}
              <List>
                {this.state.showList == "customers" &&
                  this.state.data?.customers?.map((customer, index) => (
                    <List.Item key={`cfind-${index}`}>
                      <CustomerListItem
                        customer={customer}
                        favs={getFavCustomers(() => {})}
                        onFavUpdate={(favs) =>
                          Cookies.set("favs", JSON.stringify(favs), {
                            expires: 365,
                          })
                        }
                        selectCustomer={() => {
                          this.setState({ selectedCustomer: customer });
                        }}
                      />
                    </List.Item>
                  ))}
              </List>

              {/* show leads list ..  */}
              <List>
                {this.state.showList == "leads" &&
                  this.state.data?.leads?.map((lead, index) => (
                    <List.Item key={`lfind-${index}`}>
                      <LeadListItem
                        lead={lead}
                        onClick={() => {
                          this.setState({ selectedLead: lead });
                        }}
                      />
                    </List.Item>
                  ))}
              </List>

              <GoogleMap
                mapContainerStyle={{
                  width: "100%",
                  height: "350px",
                }}
                zoom={16}
                onLoad={(map) => {
                  this.setState({ map });
                  setTimeout(
                    () => map.setCenter(this.props.currentLocation),
                    300
                  );
                }}
                onUnmount={() => this.setState({ map: null })}
                mapTypeId="roadmap"
              >
                <MapActionBar
                  routes={this.state.routes}
                  currentLocation={this.props.currentLocation}
                  sessionCheck={this.props.sessionCheck}
                />
                {this.state.routes?.length > 0 &&
                  this.state.routes.map(
                    (route, index) =>
                      route.cord1 &&
                      route.cord1.length > 2 && ( // Check if cord1 is not null or empty
                        <>
                          <PolygonF
                            key={`R-search-poly${index}`}
                            options={{
                              paths: JSON.parse(route.cord1).map((coord) => ({
                                lat: parseFloat(coord.lat),
                                lng: parseFloat(coord.lng),
                              })),
                              strokeColor: "#1abc9c",
                              strokeOpacity: 0.8,
                              strokeWeight: 2,
                              fillOpacity: 0,
                            }}
                          />

                          <PolylineF
                            key={`Plylineverticalzone${index}`}
                            path={getRouteZones(route)?.verticalLine}
                            options={{
                              strokeColor: "#697987",
                              strokeOpacity: 1,
                              strokeWeight: 2,
                            }}
                          />
                          <PolylineF
                            key={`Plylinehorizontalzone${index}`}
                            path={getRouteZones(route)?.horizontalLine}
                            options={{
                              strokeColor: "#697987",
                              strokeOpacity: 1,
                              strokeWeight: 2,
                            }}
                          />
                        </>
                      )
                  )}

                {this.state.data?.customers?.length > 0 &&
                  this.state.data?.customers?.map((customer, index) => (
                    <MarkerF
                      key={`C-search-${index}`}
                      position={JSON.parse(customer.geo_location)}
                      icon={{
                        url: constants.marker_red,
                        scaledSize: new window.google.maps.Size(32, 32), // specify the size of the icon
                      }}
                      label={{
                        text:
                          customer?.shop_name +
                          (
                            haversineDistance(
                              JSON.parse(customer.geo_location),
                              this.props.currentLocation
                            ) / 1000
                          ).toFixed(2),
                        color: "black",
                        fontSize: "10",
                      }}
                      onDblClick={() =>
                        this.setState({
                          selectedCustomer: customer,
                          modal: false,
                        })
                      }
                    />
                  ))}

                {this.state.data?.leads?.length > 0 &&
                  this.state.data?.leads?.map((lead, index) => (
                    <MarkerF
                      key={`L-search-${index}`}
                      position={JSON.parse(lead.geolocation)}
                      icon={{
                        url: constants.marker_blue,
                        scaledSize: new window.google.maps.Size(32, 32), // specify the size of the icon
                      }}
                      label={{
                        text:
                          lead?.shop_name +
                          (
                            haversineDistance(
                              JSON.parse(lead.geolocation),
                              this.props.currentLocation
                            ) / 1000
                          ).toFixed(2),
                        color: "black",
                        fontSize: "10",
                      }}
                      onClick={() => {
                        this.setState({ selectedLeadMini: lead });
                      }}
                      onDblClick={() =>
                        this.setState({ selectedLead: lead, modal: false })
                      }
                    />
                  ))}

                <MarkerF
                  position={this.props.currentLocation}
                  icon={{
                    url: constants.marker_green,
                    scaledSize: new window.google.maps.Size(32, 32), // specify the size of the icon
                  }}
                  label={{
                    text: "+",
                    fontSize: "18",
                  }}
                />
              </GoogleMap>
            </Modal.Body>
            <Modal.Footer>
              <InputGroup>
                <Input
                  style={{ width: "200px" }}
                  size="sm"
                  placeholder="Search value"
                  value={this.state.searchValue}
                  onChange={(searchValue) => this.setState({ searchValue })}
                />
                <Button
                  size="sm"
                  onClick={this.search}
                  disabled={this.state?.searchValue?.length == 0}
                >
                  <FaSearch />
                </Button>
                <Button
                  onClick={this.findNearest}
                  appearance="primary"
                  color="orange"
                  size="sm"
                >
                  Who's near?
                </Button>
                <QuickCreateLead currentLocation={this.props.currentLocation} />
              </InputGroup>
            </Modal.Footer>
          </Modal>
        )}

        {/* Lead & Custoemr View Drawer */}
        {selectedCustomer != null || selectedLead != null ? (
          <Drawer
            placement="bottom"
            size="full"
            open={selectedCustomer != null || selectedLead != null}
            onClose={() =>
              this.setState({
                selectedCustomer: null,
                selectedLead: null,
                modal: true,
              })
            }
          >
            <Drawer.Header>
              <Drawer.Title>
                View {selectedCustomer ? "Customer" : "Lead"}
              </Drawer.Title>
            </Drawer.Header>
            <Drawer.Body>
              {selectedCustomer ? (
                <CustomerView
                  data={this.state.selectedCustomer}
                  back={() => this.setState({ selectedCustomer: null })}
                  currentLocation={this.props.currentLocation}
                  sessionCheck={this.props.sessionCheck}
                />
              ) : selectedLead ? (
                <HomeLeadView
                  lead={selectedLead}
                  back={() => this.setState({ selectedLead: null })}
                  currentLocation={this.props.currentLocation}
                  sessionCheck={this.props.sessionCheck}
                  setView={(selectedLead) => this.setState({ selectedLead })}
                  loadLeads={() => {}}
                />
              ) : (
                ""
              )}
            </Drawer.Body>
          </Drawer>
        ) : (
          ""
        )}
      </>
    );
  }
}

export default QuickFinder;
