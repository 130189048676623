import React, { Component } from "react";
import {
  Button,
  Divider,
  Drawer,
  Modal,
  SelectPicker,
  Tag,
  Toggle,
  Message,
  Stack,
  InputNumber,
} from "rsuite";
import { findGeoSpots, loadRoutes } from "../../Loaders/GeneralLoder";
import CustomLoader from "../../Widgets/CustomLoader";

import {
  DrawingManager,
  GoogleMap,
  Marker,
  MarkerF,
  Polygon,
  PolygonF,
  CircleF,
  useJsApiLoader,
  InfoWindowF,
} from "@react-google-maps/api";
import CustomerListItem from "../../components/CustomerListItem";
import { getFavCustomers } from "../../Helpers/Utilities";
import Cookies from "js-cookie";
import CustomerView from "../sub-pages/mobile/customer-view";
import constants, {
  alertError,
  alertInfoSilent,
  alertInfo,
} from "../../constants";

class CustomerDataManipulation extends Component {
  state = {
    routes: [],
    route: null,
    loading: false,
    loadingText: "",
    viewCustomer: null,
    geoSpot: null,
    radius: 3,
    limitResults: 30,
    lastSaleDaysAgo: 20,
    lastFollowupDaysAgo: 20,
    data: null,
    filterShowComparisonList: false,
    filterShowCustomers: true,
  };

  loading = (loading = true, loadingText = "") =>
    this.setState({ loading, loadingText });

  componentDidMount = () => {
    loadRoutes((routes) =>
      this.setState({ routes }, () => this.switchRoute(routes[0]?.id))
    );
  };

  switchRoute = (route) => {
    var { routes } = this.state;

    if (!route) {
      return;
    }

    this.setState({ route, geoSpot: null, geoSpotIndex: null }, () => {
      if (this.state.map) {
        this.state.map.setCenter(JSON.parse(routes[0].cord1)[0]);
      }
    });
  };

  submit = () => {
    var {
      radius,
      limitResults,
      lastSalesDaysAgo,
      geoSpot,
      route,
      lastSaleDaysAgo,
      lastFollowupDaysAgo,
    } = this.state;

    var reqData = {
      radius,
      limitResults,
      lastSalesDaysAgo,
      geoSpot,
      route,
      lastSaleDaysAgo,
      lastFollowupDaysAgo,
    };

    this.loading(true, "Generating Eligible Customers to Contact..");
    fetch(constants.url + `dm-find-geospot-customers`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("u-token")}`,
      },
      body: JSON.stringify(reqData),
    })
      .then((response) => response.json())
      .then((data) => {
        this.loading(false);
        this.setState({ data });
      })
      .catch((error) => {
        alertError("Request failed: " + error?.message);
        this.loading(false);
      });
  };

  render() {
    var {
      routes,
      route,
      data,
      geoSpot,
      geoSpotIndex,
      filterShowCustomers,
      filterShowComparisonList,
    } = this.state;
    var selectedRoute = routes.find((r) => r.id == route);

    return (
      <div style={{ maxWidth: "100vw", overFlowX: "hidden" }}>
        <CustomLoader
          full
          text={this.state.loadingText}
          loading={this.state.loading}
        />

        <Divider>
          <Tag color="orange">Customer</Tag> Manipulation ({routes.length}{" "}
          routes)
          {selectedRoute ? (
            <Button
              appearance="link"
              color="yellow"
              onClick={() => {
                this.loading(true, "Updating GeoSpots");
                findGeoSpots(selectedRoute, (data) => {
                  alertInfo("GeoSpots Set");
                  this.setState(({ routes }) => ({
                    routes: routes.map((r) =>
                      r.id === selectedRoute.id ? data.route : r
                    ),
                  }));
                  this.loading(false);
                });
              }}
            >
              Update GeoSpots
            </Button>
          ) : (
            ""
          )}
        </Divider>
        <SelectPicker
          size="sm"
          // searchable={false}
          data={routes?.map((route) => ({
            label: route.route_name,
            value: route.id,
          }))}
          value={this.state.route}
          onChange={(route) => this.switchRoute(route)}
        />

        {/* Map */}

        <GoogleMap
          mapContainerStyle={{
            width: "100%",
            height: `calc(79vh - ${geoSpot ? "30px" : "0px"} - ${
              data?.filteredCustomers ? "30px" : "0px"
            })`,
          }}
          zoom={12}
          onLoad={(map) => {
            this.setState({ map });
            setTimeout(() => map.setCenter(this.props.currentLocation), 300);
          }}
        >
          {selectedRoute ? (
            <>
              <PolygonF
                options={{
                  paths: JSON.parse(selectedRoute.cord1)?.map((coord) => ({
                    lat: parseFloat(coord.lat),
                    lng: parseFloat(coord.lng),
                  })),
                  strokeColor: "#1abc9c",
                  strokeOpacity: 0.8,
                  strokeWeight: 2,
                  fillOpacity: 0,
                }}
              />

              {geoSpot && geoSpotIndex != null && (
                <MarkerF
                  key={
                    geoSpot.lat +
                    "-" +
                    geoSpot.lng +
                    " - selected index" +
                    geoSpotIndex
                  }
                  position={geoSpot}
                  icon={{
                    url: constants.marker_green,
                    scaledSize: new window.google.maps.Size(32, 32), // specify the size of the icon
                  }}
                  label={{
                    text: `${geoSpotIndex + 1}`,
                    color: "black",
                    fontSize: "10",
                  }}
                  onClick={() => this.setState({ geoSpot, geoSpotModal: true })}
                  // onDblClick={() =>
                  //   this.setState({ geoSpot, geoSpotModal: true })
                  // }
                  onRightClick={() => this.setState({ geoSpot: null })}
                />
              )}

              {!geoSpot &&
                selectedRoute.cord3 &&
                JSON.parse(selectedRoute.cord3)?.map((geoSpotThis, index) => (
                  <MarkerF
                    key={`${selectedRoute.id}spot${index}`}
                    position={geoSpotThis}
                    icon={{
                      url: constants.marker_red,
                      scaledSize: new window.google.maps.Size(32, 32), // specify the size of the icon
                    }}
                    label={{
                      text: `${index + 1}`,
                      color: "black",
                      fontSize: "10",
                    }}
                    onClick={() =>
                      this.setState({
                        geoSpot: geoSpotThis,
                        geoSpotIndex: index,
                      })
                    }
                    onDblClick={() =>
                      this.setState({
                        geoSpot: geoSpotThis,
                        geoSpotModal: true,
                      })
                    }
                    onRightClick={() =>
                      alertInfoSilent(
                        `GeoSpot ${index + 1}  has ${
                          geoSpotThis?.count
                        } Customer density, and ${
                          geoSpotThis?.hot_day
                        } is the hottest.`
                      )
                    }
                  />
                ))}

              {filterShowCustomers &&
                geoSpot &&
                data &&
                data.filteredCustomers?.map((item, index) => (
                  <MarkerF
                    key={`${item.customer.id}customer${index}`}
                    position={JSON.parse(item.customer.geo_location)}
                    icon={{
                      url: constants.marker_yellow,
                      scaledSize: new window.google.maps.Size(32, 32), // specify the size of the icon
                    }}
                    label={{
                      text: `${item.customer.shop_name}`,
                      color: "black",
                      fontSize: "10",
                    }}
                    onClick={() =>
                      this.setState({ clickedCustomer: item.customer })
                    }
                    onDblClick={() =>
                      this.setState({ viewCustomer: item.customer })
                    }
                  >
                    {this.state.clickedCustomer?.id === item.customer.id && (
                      <InfoWindowF
                        position={JSON.parse(item.customer.geo_location)} // Explicit position
                        onCloseClick={() =>
                          this.setState({ clickedCustomer: null })
                        }
                      >
                        <span style={{ padding: "4px" }}>
                          <div
                            style={{
                              width: "97%",
                              padding: "3px",
                              background: "#ededed",
                              color: "gray",
                              marginTop: "7px",
                            }}
                          >
                            <strong>
                              {item.probability} chances to close:
                            </strong>{" "}
                            &nbsp; {item.reason}{" "}
                            <Button
                              onClick={() => {
                                data.filteredCustomers =
                                  data.filteredCustomers.filter(
                                    (c) => c.customer.id !== item.customer.id
                                  );
                                this.setState({ data, clickedCustomer: null });
                              }}
                            >
                              Done or Ignore
                            </Button>
                          </div>

                          <CustomerListItem
                            key={item.customer.id}
                            customer={item.customer}
                            favs={getFavCustomers(() => {})}
                            onFavUpdate={(favs) =>
                              Cookies.set("favs", JSON.stringify(favs), {
                                expires: 365,
                              })
                            }
                            selectCustomer={() => {
                              this.setState({
                                viewCustomer: item.customer,
                              });
                            }}
                          />
                          <br />
                          <Button
                            appearance="primary"
                            color="green"
                            onClick={() =>
                              (window.location.href = `https://www.google.com/maps/dir/?api=1&destination=${
                                JSON.parse(item.customer.geo_location).lat
                              },${
                                JSON.parse(item.customer.geo_location).lng
                              }&travelmode=motorcycle`)
                            }
                            size="xs"
                          >
                            NAVIGATE
                          </Button>
                          <br />
                        </span>
                      </InfoWindowF>
                    )}
                  </MarkerF>
                ))}

              {filterShowComparisonList &&
                geoSpot &&
                data &&
                data.comparisonList?.map((customer, index) => (
                  <MarkerF
                    key={`${customer.id}customer${index}`}
                    position={JSON.parse(customer.geo_location)}
                    icon={{
                      url: constants.marker_pink,
                      scaledSize: new window.google.maps.Size(32, 32), // specify the size of the icon
                    }}
                    label={{
                      text: `${customer.shop_name}`,
                      color: "black",
                      fontSize: "10",
                    }}
                    onClick={null}
                    onDblClick={() => this.setState({ viewCustomer: customer })}
                  />
                ))}
            </>
          ) : null}

          {geoSpot == null ? (
            ""
          ) : (
            <>
              {/* Clear existing circles by resetting geoSpot before updating */}
              <CircleF
                key={
                  geoSpot.lat + "-" + geoSpot.lng + "-comparison" // Add timestamp to force re-render
                }
                center={geoSpot}
                radius={this.state.radius * 1000}
                options={{
                  fillOpacity: 0,
                  strokeColor: "#4A90E2",
                  strokeOpacity: 0.8,
                  strokeWeight: 2,
                }}
              />
            </>
          )}
        </GoogleMap>
        <br />
        {geoSpot ? (
          <Button
            appearance="link"
            onClick={() => this.setState({ geoSpotModal: true })}
          >
            Open GeoSpot Window ({geoSpotIndex + 1})
          </Button>
        ) : (
          ""
        )}
        {filterShowCustomers && data?.filteredCustomers && (
          <Button block onClick={() => this.setState({ customersModal: true })}>
            {" "}
            View AI Generated Customers ({data?.filteredCustomers?.length})
          </Button>
        )}

        {/* List item model */}
        <Modal
          open={this.state.customersModal}
          onClose={() => this.setState({ customersModal: null })}
        >
          <Modal.Header>
            <Modal.Title>
              Display AI Generated Customers ({data?.filteredCustomers?.length})
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {data?.filteredCustomers?.map((item, index) => (
              <div
                key={`customers-aigenerated${item.customer.id}`}
                style={{ border: "2px solid #ededed", marginBottom: "4px" }}
              >
                <div
                  style={{
                    width: "97%",
                    padding: "3px",
                    background: "#ededed",
                    color: "gray",
                    marginTop: "7px",
                  }}
                >
                  <strong>{item.probability} chances to close:</strong> &nbsp;{" "}
                  {item.reason}{" "}
                  <Button
                    onClick={() => {
                      data.filteredCustomers = data.filteredCustomers.filter(
                        (c) => c.customer.id !== item.customer.id
                      );
                      this.setState({ data });
                    }}
                  >
                    Done or Ignore
                  </Button>
                </div>

                <CustomerListItem
                  customer={item?.customer}
                  favs={getFavCustomers(() => {})}
                  onFavUpdate={(favs) =>
                    Cookies.set("favs", JSON.stringify(favs), {
                      expires: 365,
                    })
                  }
                  selectCustomer={() => {
                    this.setState({ viewCustomer: item?.customer });
                  }}
                />
              </div>
            ))}

            <br />
          </Modal.Body>
        </Modal>

        {/* SELECTED GEOSPOT MODAL */}
        <Modal
          open={this.state.geoSpotModal}
          onClose={() => this.setState({ geoSpotModal: null })}
        >
          <Modal.Header>
            <Modal.Title>
              Selected GeoSpot has {geoSpot?.count} customer density{" "}
              {geoSpot?.hot_day ? (
                <Tag
                  color="red"
                  onClick={() => alert(JSON.stringify(geoSpot?.sales_by_day))}
                >
                  {geoSpot?.hot_day} is the Hottest
                </Tag>
              ) : (
                ""
              )}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Divider>Find Customers Here</Divider>
            <i>Radius in KM</i>
            <InputNumber
              value={this.state.radius}
              placeholder="radius"
              onChange={(radius) => this.setState({ radius })}
            />
            <i>Limit Result</i>
            <InputNumber
              value={this.state.limitResults}
              placeholder="limitResults"
              onChange={(limitResults) => this.setState({ limitResults })}
            />
            <i>Last Sale #days Ago</i>
            <InputNumber
              value={this.state.lastSaleDaysAgo}
              placeholder="lastSaleDaysAgo"
              onChange={(lastSaleDaysAgo) => this.setState({ lastSaleDaysAgo })}
            />
            <i>Last Followup #days Ago</i>
            <InputNumber
              value={this.state.lastFollowupDaysAgo}
              placeholder="lastFollowupDaysAgo"
              onChange={(lastFollowupDaysAgo) =>
                this.setState({ lastFollowupDaysAgo })
              }
            />

            {data && (
              <div>
                <Divider>Visibility Filters</Divider>

                <Stack justifyContent="space-between">
                  <strong>
                    List used to Compare ({data.comparisonList?.length}{" "}
                    Customers)
                  </strong>
                  <Toggle
                    size="xs"
                    checked={this.state.filterShowComparisonList}
                    checkedChildren="Comparison List"
                    unCheckedChildren="Comparison List"
                    onChange={(filterShowComparisonList) => {
                      this.setState({ filterShowComparisonList });
                    }}
                  />
                </Stack>

                <br />
                <Stack justifyContent="space-between">
                  <strong>
                    AI Generated Customers ({data.filteredCustomers?.length}{" "}
                    Customers)
                  </strong>
                  <Toggle
                    size="xs"
                    checked={this.state.filterShowCustomers}
                    checkedChildren="Showing"
                    unCheckedChildren="Hidden"
                    onChange={(filterShowCustomers) => {
                      this.setState({ filterShowCustomers });
                    }}
                  />
                </Stack>

                <br />
                <br />
                {data?.whatLearned ? (
                  <Message>
                    <strong>AI Learned: </strong> {data?.whatLearned}
                  </Message>
                ) : (
                  ""
                )}
              </div>
            )}
          </Modal.Body>
          <Modal.Footer
            style={{ background: "black", color: "white", padding: "4px" }}
          >
            <Button onClick={this.submit} appearance="ghost" color="red">
              SUBMIT
            </Button>

            <Button
              onClick={() =>
                this.setState({ geoSpot: null, geoSpotModal: false })
              }
              appearance="ghost"
              color="yellow"
            >
              CLOSE SPOT
            </Button>
          </Modal.Footer>
        </Modal>

        {/* CUSTOMER DISPLAY MODAL */}
        {this.state.viewCustomer != null && (
          <Drawer
            open={this.state.viewCustomer != null}
            onClose={() => this.setState({ viewCustomer: null })}
            placement="bottom"
            size="full"
          >
            <Drawer.Header>
              <Drawer.Title>
                Viewing Customer - {this.state.viewCustomer?.shop_name}
              </Drawer.Title>
            </Drawer.Header>
            <Drawer.Body>
              <CustomerView
                data={this.state.viewCustomer}
                back={() => this.setState({ viewCustomer: null })}
                currentLocation={this.props.currentLocation}
                sessionCheck={this.props.sessionCheck}
              />
            </Drawer.Body>
          </Drawer>
        )}
      </div>
    );
  }
}

export default CustomerDataManipulation;
