import { InfoWindowF, MarkerF } from "@react-google-maps/api";
import React, { Component } from "react";
import { Button } from "rsuite";
import CustomerListItem from "../../components/CustomerListItem";
import constants, { alertError } from "../../constants";
import { getFavCustomers } from "../../Helpers/Utilities";
import { loadReturns, loadReturnsData } from "../../Loaders/GeneralLoder";

class MapReturns extends Component {
  state = {
    Returns: [],
    loading: false,
    selected: false,
  };

  loading = (loading = true) => this.setState({ loading });

  load = () => {
    if (this.props.selectedRoutes.length == 0) {
      return;
    }
    this.loading(true);
    loadReturnsData(this.props.selectedRoutes, (Returns) => {
      this.loading(false);
      this.setState({ Returns });
    });
  };
  render() {
    var { Returns } = this.state;
    return (
      <>
        <div
          className={`mab-button ${
            this.state.loading ? "mab-button-loading" : ""
          } ${this.state.selected ? "mab-button-selected" : ""}`}
          onClick={() => {
            if (this.state.selected) {
              this.setState({ Returns: [], selected: false });
            } else {
              this.setState({ Returns: [], selected: true }, this.load);
            }
          }}
        >
          R
        </div>

        {Returns?.map((ret, index) => (
          <MarkerF
            key={`retmab${index}`}
            position={JSON.parse(ret.customer?.geo_location)}
            icon={{
              url: constants.marker_black,
              scaledSize: new window.google.maps.Size(32, 32), // specify the size of the icon
            }}
            label={{
              text: "R",
              color: "white",
              fontSize: "6",
            }}
            onClick={() => {
              setTimeout(() => {
                if (this.state.clickedCustomer?.id !== ret.customer.id) {
                  this.setState({ clickedCustomer: ret.customer });
                }
              }, 100); // delay by 100ms
            }}
            onDblClick={() =>
              (window.location.href = `https://www.google.com/maps/dir/?api=1&destination=${
                JSON.parse(ret.customer?.geo_location).lat
              },${
                JSON.parse(ret.customer?.geo_location).lng
              }&travelmode=motorcycle`)
            }
          >
            {/* Render InfoWindow only for the clickedCustomer */}
            {this.state.clickedCustomer?.id === ret.customer.id && (
              <InfoWindowF
                position={JSON.parse(ret.customer?.geo_location)} // Explicit position
                onCloseClick={() => this.setState({ clickedCustomer: null })}
              >
                <span style={{ padding: "4px" }}>
                  <strong>Sales Return</strong>
                  <br />
                  <CustomerListItem
                    key={ret.customer.id}
                    customer={ret.customer}
                    favs={[]}
                    onFavUpdate={() => {}}
                    selectCustomer={() =>
                      this.props.selectCustomer(ret.customer)
                    }
                  />
                  <br />
                  <Button
                    appearance="primary"
                    color="green"
                    onClick={() =>
                      (window.location.href = `https://www.google.com/maps/dir/?api=1&destination=${
                        JSON.parse(ret.customer?.geo_location).lat
                      },${
                        JSON.parse(ret.customer?.geo_location).lng
                      }&travelmode=motorcycle`)
                    }
                    size="xs"
                  >
                    NAVIGATE
                  </Button>
                  <br />
                </span>
              </InfoWindowF>
            )}
          </MarkerF>
        ))}
      </>
    );
  }
}

export default MapReturns;
