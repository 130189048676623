import constants, { alertError, alertInfo } from "../../constants";
import Cookies from "js-cookie";

export function refineText(text, onDone, manglish = true) {
  if (text?.length < 5) {
    onDone(null);
    alertError("Invalid text input, increase the text contents..");
    return;
  }
  var reqText = "Make the given text grammatically correct and refine it.";
  if (manglish) {
    reqText +=
      " If the content is in malayalam (sometimes typed as english like 'njan' mean I am) need to be translated and need the response in english only.";
  }

  reqText +=
    " Also just return only the refined text, as I can copy paste. The input text is: " +
    text;
  generateText(reqText, onDone);
}

export function generateText(text, onDone) {
  fetch(constants.url + "generate-text", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Cookies.get("u-token")}`,
    },
    body: JSON.stringify({ text }),
  })
    .then((response) => response.json())
    .then((data) => {
      onDone(data);
    })
    .catch((error) => {
      alertError("Unable to refine text");
      console.error(error);
      onDone(null);
    });
}

export function handleConversation(
  conversation,
  systemInstruction,
  tutor,
  onDone
) {
  fetch(constants.url + "stack-ai-chat", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Cookies.get("u-token")}`,
    },
    body: JSON.stringify({
      conversation,
      system_instruction: systemInstruction,
      tutor,
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      onDone(data);
    })
    .catch((error) => {
      alertError("Unable to process the conversation.");
      console.error(error);
      onDone(null);
    });
}
